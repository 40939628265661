// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

//Import components
import PageBreadcrumb from '../../../components/Shared/PageBreadcrumb';

class PageTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pathItems: [
        //id must required
        // { id: 1, name: "Landrick", link: "/index" },
        // { id: 2, name: "Page", link: "#" },
        // { id: 3, name: "Utility", link: "#" },
        // { id: 4, name: "Terms" },
      ],
      col1: true,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    };
    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col4 = this.t_col4.bind(this);
    this.t_col5 = this.t_col5.bind(this);
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    });
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
      col1: false,
      col3: false,
      col4: false,
      col5: false,
    });
  }
  t_col3() {
    this.setState({
      col3: !this.state.col3,
      col2: false,
      col1: false,
      col4: false,
      col5: false,
    });
  }
  t_col4() {
    this.setState({
      col4: !this.state.col4,
      col2: false,
      col3: false,
      col1: false,
      col5: false,
    });
  }
  t_col5() {
    this.setState({
      col5: !this.state.col5,
      col2: false,
      col3: false,
      col1: false,
      col4: false,
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.getElementById('topnav').classList.add('nav-sticky');
    } else {
      document.getElementById('topnav').classList.remove('nav-sticky');
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}
        <PageBreadcrumb
          title="Манай үйлчилгээ"
          pathItems={this.state.pathItems}
        />
        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <Card className="shadow border-0 rounded">
                  <CardBody>
                    <h5 className="card-title">Промотер</h5>
                    <p className="text-muted">
                      <b className="text-secondary">
                        Бүтээгдэхүүнийг эцсийн хэрэглэгчдэд таниулах,
                        сурталчлах, амтлуулах ажилтан зуучлах үйлчилгээ
                      </b>
                    </p>
                    <h5 className="card-title">Мерчиндайзер</h5>
                    <p className="text-muted">
                      <b className="text-secondary">
                        Бараа бүтээгдэхүүнийг худалдааны цэгт стандартын дагуу
                        өрж байршуулах ажилтан зуучлах үйлчилгээ
                      </b>
                    </p>

                    <h5 className="card-title">Эзэгтэйн туслах</h5>
                    <p className="text-muted">
                      <b className="text-secondary">
                        Завгүй болон хүн хүч шаардлагатай үед танд туслах
                        үйлчилгээ
                      </b>
                      <ul className="list-unstyled feature-list text-muted">
                        <li>
                          <i>
                            <FeatherIcon
                              icon="arrow-right"
                              className="fea icon-sm me-2"
                            />
                          </i>
                          Xоол хийх, хийхэд туслах
                        </li>
                        <li>
                          <i>
                            <FeatherIcon
                              icon="arrow-right"
                              className="fea icon-sm me-2"
                            />
                          </i>
                          Цэвэрлэгээ хийлцэх / гэр доторх цэвэрлэгээ /
                        </li>
                        <li>
                          <i>
                            <FeatherIcon
                              icon="arrow-right"
                              className="fea icon-sm me-2"
                            />
                          </i>
                          Мах хэрчих болон туслах үйлчилгээ
                        </li>
                        <li>
                          <i>
                            <FeatherIcon
                              icon="arrow-right"
                              className="fea icon-sm me-2"
                            />
                          </i>
                          Хүнс цуглуулалцах болон туслах үйлчилгээ
                        </li>
                        <li>
                          <i>
                            <FeatherIcon
                              icon="arrow-right"
                              className="fea icon-sm me-2"
                            />
                          </i>
                          Ахмад настан асаргаа
                        </li>
                      </ul>
                    </p>
                    <h5 className="card-title">Сошиал хөтлөлт</h5>
                    <p className="text-muted">
                      <b className="text-secondary">
                        Вэбсайт болон фэйсбүүк хуудас хөтлөх, идэвхитэй байдлыг
                        ханган ажиллах Сошиал ажилтан зуучлах үйлчилгээ
                      </b>
                    </p>
                    <h5 className="card-title">Оффис цэвэрлэгээний ажилтан</h5>
                    <p className="text-muted">
                      <b className="text-secondary">
                        Оффисын өдөр тутмын цэвэрлэгээний цагийн ажилтанг
                        зуучлах үйлчилгээ
                      </b>
                    </p>
                    <h5 className="card-title">School police</h5>
                    <p className="text-muted">
                      <b className="text-secondary">
                        Завгүй эцэг эх та бүхний өмнөөс ерөнхий боловсролын
                        сургуулиудад SCHOOL POLICE хийх үйлчилгээ
                      </b>
                    </p>
                    <h5 className="card-title">Эвэнт модель угтагч</h5>
                    <p className="text-muted">
                      <b className="text-secondary">
                        Бүх төрлийн арга хэмжээний угтагч, туслах ажилтнаар
                        зуучлах үйлчилгээ
                      </b>
                    </p>
                    <h5 className="card-title">Инфлюнсерийн бэлэг хүргэлт</h5>
                    <p className="text-muted">
                      <b className="text-secondary">
                        Олны танил хүмүүст брэнд бүтээгдэхүүний нэрийн өмнөөс
                        бэлэг хүргэх үйлчилгээ
                      </b>
                    </p>
                    <h5 className="card-title">Үйлдвэрийн туслах ажилтан</h5>
                    <p className="text-muted">
                      <b className="text-secondary">
                        Бараа бүтээгдэхүүний шошго наалт, бэлэг боох зэрэг
                        туслах үйлчилгээ
                      </b>
                    </p>

                    {/* <div className="mt-3">
                      <Link to="#" className="btn btn-primary mt-2 me-2">
                        Accept
                      </Link>
                      <Link to="#" className="btn btn-outline-primary mt-2">
                        Decline
                      </Link>
                    </div> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PageTerms;
